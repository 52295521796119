import React, { useState, useContext, useEffect } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { Box, CircularProgress, Container, MenuItem, TextareaAutosize, FormControl, InputLabel, Select } from "@mui/material";
import DatePickerSelect from "../DatePickerSelect/DatePickerSelect";
import Button from '../../styled/Button/Button';
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { CREATE_PROCESS } from "../../apollo/mutations/createProcess";
import { CREATE_TASK_MUTATION } from "../../apollo/mutations/createTaskMutation";
import { CREATE_FORM_MUTATION } from "../../apollo/mutations/createFormMutation";
import { CREATE_EVENT_MUTATION } from "../../apollo/mutations/createTaskAndEventsMutation";
import { GET_TASK_FLOW_TEMPLATES_FOR_MILESTONE } from "../../apollo/queries/getTaskFlowTemplatesQuery";
import { GET_TASK_FLOW_TEMPLATES_QUERY } from "../../apollo/queries/getTaskFlowTemplatesQuery";
import { FIND_ASSET_PROPERTIES } from "../../apollo/queries/findAssetProperties";
import { CREATE_TASK_FLOW_AND_SUBTASKS_MUTATION } from "../../apollo/mutations/createTaskFlowAndSubtasksMutation";
import { CREATE_TASK_FLOW_TEMPLATE_MUTATION } from "../../apollo/mutations/createTaskFlowTemplateMutation";
import { GET_PORTFOLIO_MANAGER } from "../../apollo/queries/getPortfolioManager";
import { CREATE_COMMENT_AND_EVENTS_MUTATION } from "../../apollo/mutations/createCommentAndEventsMutation";
import { GET_TEAMS_BY_OWNER } from "../../apollo/queries/getTeamsByOwner";
import { MessageContext } from "../../context/MessageContext";
import { AssetContext } from "../../context/AssetContext";
import Typography from "../../styled/Typography/Typography";
import "react-datepicker/dist/react-datepicker.css";
import "./asset-referral.scss";
import { START_REFERRAL_PROCESS_MUTATION } from "../../apollo/mutations/startReferralProcessMutation";
import { REFER_PREVIOUS_PROCESS } from "../../apollo/mutations/referPreviousProcess";
import { GET_PROCESS_TYPES } from "../../apollo/queries/getProcessTypes";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { formatProcessType } from "../../utilities";
import { GENERIC_JUDICIAL, GENERIC_NONJUDICIAL, isJudicial } from "../../utilities/genericSteps";
import HasArchivedProcess from "../Modals/HasArchivedProcess";
const fcSteps = require('../../utilities/foreclosureSteps.json');
const bkSteps = require('../../utilities/bkSteps.json');
import { checkAssetNextMilestoneAction } from "../../utilities/milestoneActions";
import { TextInput } from "../../styled/TextInput/TextInput";
import { CREATE_REFERRAL_REPORT_DOCUMENT } from "../../apollo/mutations/createReferralReportDocument";

const INITIAL_ERROR_STATE = {
  assignedFirm: false,
  referralStart: false,
  comment: false,
};

const loanTypes = [
  'Conventional - conforming',
  'Conventional - jumbo',
  'Non-QM',
  'ARM',
  'I/O',
  'FHA',
  'VA',
  'USDA',
  '203k',
  'Other',
]

const StyledTextareaAutosize = styled(TextareaAutosize)`
  background: ${(props) => props.theme.themeColor.backgroundBody};
  color: ${(props) => props.theme.themeColor.bodyMain};
  font-family: IBM Plex Sans;
  font-size: 1rem;
  padding: 5px;
`

const getProcessTypes = (processTypes) => {
  let types = processTypes.processTypes.map((type) => (
    { name: formatProcessType(type.name), value: type.name}
  ))
  if (isJudicial) {
    types = types.filter((type) => type.value !== "judicial")
  } else {
    types = types.filter((type) => type.value !== "nonJudicial")
  }
  return types;
}

const getSteps = (processType, asset) => {
  if (processType === "stateDefault" || processType === "foreclosure") {
    return [
      ...fcSteps.transformedRow.filter((step) => {
        if (asset.borough) {
          const fStep = step.state === asset.state && step.borough && step.borough.toUpperCase() === asset.borough.toUpperCase();
          if (!fStep) return step.state === asset.state && step.borough === null;
          return fStep;
        } else {
          return step.state === asset.state && step.borough === null;
        }
      }),
    ][0].steps;
  }
  else if (processType === "judicial") 
    return GENERIC_JUDICIAL
  else if (processType === "nonJudicial")
    return GENERIC_NONJUDICIAL
  else if (processType === "chapter7") {
    return [...bkSteps.chapter7.steps]
  }
  else if (processType === "chapter13" || processType === "chapter11") {
    return [...bkSteps.chapter13and11.steps]
  }
}

const getBillingStatus = (processType) => {
  if (processType === "stateDefault" || processType === "judicial" || processType === "nonJudicial")
    return "IN_FORECLOSURE";
  else if (processType === "chapter7" || processType === "chapter11" || processType === "chapter13")
    return "IN_BANKRUPTCY";
}

export default function AssetReferral({
  user,
  portfolioID,
  loanNumber,
  state,
  hasDeletedProcess,
  client,
}) {
  const theme = useTheme();
  const { userID, userType } = user;
  const { addMessage } = useContext(MessageContext);
  const { asset, setAsset } = useContext(AssetContext);

  const [allProps, setAllProps] = useState([]);
  const [selectedProps, setSelectedProps] = useState([]);
  const [lawFirms, setLawFirms] = useState([]);
  const [assignedFirm, setAssignedFirm] = useState(undefined);
  const [referralStart, setReferralStart] = useState(null);
  const [allProcessTypes, setAllProcessTypes] = useState([]);
  const [processType, setProcessType] = useState(null);
  const [allSteps, setAllSteps] = useState([]);
  const [selectedStep, setSelectedStep] = useState(null);

  const [loanType, setLoanType] = useState("");
  const [currentAITNO, setCurrentAITNO] = useState("");
  const [nextDueDate, setNextDueDate] = useState(null);
  const [totalPayoff, setTotalPayoff] = useState("");
  const [totalReinstatement, setTotalReinstatement] = useState("");

  const [comment, setComment] = useState("");
  const [showDeletedModal, setShowDeletedModal] = useState(hasDeletedProcess);
  const [errorForm, setFormError] = useState(INITIAL_ERROR_STATE);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { data, loading } = useQuery(GET_TEAMS_BY_OWNER, {
    variables: { portfolioManager: userID },
  });
  const { data: processTypes } = useQuery(GET_PROCESS_TYPES);
  const { data: assetProperties } = useQuery(FIND_ASSET_PROPERTIES, {
    variables: { assetID: asset.id }
  })

  const [startReferral, response] = useMutation(START_REFERRAL_PROCESS_MUTATION);
  const [createProcess] = useMutation(
    CREATE_PROCESS
  );
  const [referPreviousProcess] = useMutation(REFER_PREVIOUS_PROCESS);
  const [createComment] = useMutation(CREATE_COMMENT_AND_EVENTS_MUTATION);
  const [createTask] = useMutation(CREATE_TASK_MUTATION);
  const [createForm] = useMutation(CREATE_FORM_MUTATION);
  const [getFlowTemplates] = useLazyQuery(GET_TASK_FLOW_TEMPLATES_FOR_MILESTONE, {
    variables: { stepID: selectedStep?.stepID, state: asset.state }
  });
  const [taskFlowTemplates] = useLazyQuery(GET_TASK_FLOW_TEMPLATES_QUERY, {
    variables: { portfolioID }
  })
  const [createFlow] = useMutation(CREATE_TASK_FLOW_AND_SUBTASKS_MUTATION);
  const [createFlowTemplate] = useMutation(CREATE_TASK_FLOW_TEMPLATE_MUTATION);
  const { data: dataPortfolioManager } = useQuery(GET_PORTFOLIO_MANAGER, {
    skip: !portfolioID,
    variables: { portfolioID },
  });

  const [createEvent] = useMutation(CREATE_EVENT_MUTATION);

  const [createReferralReportDocument] = useMutation(CREATE_REFERRAL_REPORT_DOCUMENT);

  useEffect(() => {
    if (data) {
      const onlyLawfirms = data.findTeamsByOwner.filter((team) => team.type === "LAWFIRM");
      const stateLawfirms = onlyLawfirms.filter((firm) => firm.states?.includes(state));
      setLawFirms(stateLawfirms)
    }
    if (processTypes) {
      setAllProcessTypes(getProcessTypes(processTypes));
    }
    if (assetProperties) {
      const eligibleProps = assetProperties.findAssetProperties.filter((prop) => 
        prop.state === asset.state && !prop.activeProcess && !prop.activeProcessID
      );

      setAllProps([...eligibleProps, asset]);
      setSelectedProps([asset])
    }
  }, [data, processTypes, assetProperties])

  useEffect(() => {
    if (processType) {
      const steps = getSteps(processType.value, asset);
      setSelectedStep(steps[1]);
      setAllSteps(steps);
    }
  }, [processType])

  function formValidation() {
    let hasError = false;
    if (assignedFirm === null) {
      setFormError((prevState) => ({ ...prevState, assignedFirm: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, assignedFirm: false }));
    }

    if (comment.length === 0) {
      setFormError((prevState) => ({ ...prevState, comment: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, comment: false }));
    }

    if (referralStart === null) {
      setFormError((prevState) => ({ ...prevState, referralStart: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, referralStart: false }));
    }

    if (loanType === "") {
      setFormError((prevState) => ({ ...prevState, loanType: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, loanType: false }));
    }

    if (currentAITNO === "") {
      setFormError((prevState) => ({ ...prevState, currentAITNO: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, currentAITNO: false }));
    }

    if (nextDueDate === null) {
      setFormError((prevState) => ({ ...prevState, nextDueDate: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, nextDueDate: false }));
    }

    if (totalPayoff === "") {
      setFormError((prevState) => ({ ...prevState, totalPayoff: true }));
      hasError = true;
    }
    else {
      setFormError((prevState) => ({ ...prevState, totalPayoff: false }));
    }

    if (totalReinstatement === "") {
      setFormError((prevState) => ({ ...prevState, totalReinstatement: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, totalReinstatement: false }));
    }

    return hasError;
  }

  async function handleSubmit(event, prevProcess) {
    event.preventDefault();
    if (!formValidation()) {
      setIsLoading(true);
      const data = {
        assetID: asset.id,
        portfolioID: portfolioID,
        portfolio: portfolioID,
        referredAsset: asset.id,
        loanNumber: loanNumber,
        state: state,
        referralStartDate: referralStart,
        referralStatus: "PENDING",
        referredBy: userID,
        teamID: assignedFirm.id,
        comment: comment,
        statusValue: selectedStep.stepID,
        billingStatus: getBillingStatus(processType.value),  
      };
      const additionalProperties = selectedProps.filter((prop) => prop.id !== asset.id).map((prop) => prop.id);
      if (asset.propertyID) data.propertyID = asset.propertyID;
      if (additionalProperties.length > 0)
        data.additionalProperties = additionalProperties;

      const commentData = {
        milestoneID: selectedStep.stepID,
        description: comment,
      };
      const eventData = {
        type: "comment",
        description: comment,
        milestoneID: selectedStep.stepID,
        createdDate: new Date(),
      };

      // First, try to create the PDF and upload to google, then move foward with the referral
      try {
        createReferralReportDocument({
          variables: {
            input: {
              assetID: asset.id,
              loanType: loanType.value,
              currentAITNO,
              nextDueDate,
              totalPayoff,
              totalReinstatement,
              lawfirmID: assignedFirm.id,
              additionalProperties,
            }
          }
        });
      } catch (error) {
        setErrorMessage(error);
        addMessage({
          message:
            "The server encountered an error while creating the referral report. Please try again.",
          type: "error",
        });
        return;
      }

      try {
        let newActiveProcess;
        if (prevProcess) {
          const processResult = await referPreviousProcess({
            variables: {
              assetID: asset.id,
              processID: prevProcess,
            }
          });
          const { process } = processResult.data.createProcess;
          newActiveProcess = process;
          eventData.process = process.id;
          commentData.process = process.id;
          process.processType = processType.value;

          checkAssetNextMilestoneAction(
            asset, process, portfolioID, selectedStep, createTask, createForm,
            createEvent, dataPortfolioManager, getFlowTemplates, taskFlowTemplates,
            createFlow, createFlowTemplate
          );
        } else {
          const processResult = await createProcess({
            variables: {
              input: {
                asset: asset.id,
                stepID: selectedStep.stepID,
                startStepID: selectedStep.stepID,
                processType: processType.value,
                processSteps: allSteps && allSteps,
                startDate: referralStart,
              },
              additionalProperties: selectedProps.filter((prop) => prop.id !== asset.id).map((prop) => prop.id),
            }
          });
          newActiveProcess = processResult.data.createProcess;
          data.referredProcess = processResult.data.createProcess.id;

          // create open event milestone
          const openMilestoneEvent = {
            type: "milestone-opened",
            createdDate: new Date(),
            milestoneID: selectedStep.stepID,
            description: `${selectedStep.stepID} ${selectedStep.label}`,
            process: newActiveProcess.id,
          };

          await createEvent({
            variables: {
              event: openMilestoneEvent
            }
          });

          const process = processResult.data.createProcess;
          eventData.process = process.id;
          commentData.process = process.id;
          process.processType = processType.value;
          checkAssetNextMilestoneAction(
            asset, process, portfolioID, selectedStep, createTask, createForm,
            createEvent, dataPortfolioManager, getFlowTemplates, taskFlowTemplates,
            createFlow, createFlowTemplate
          );
        }
        await createComment({
          variables: {
            comment: commentData,
            event: eventData,
          },
        });

        const refResp = await startReferral({
          variables: {
            input: data,
          },
        });

        newActiveProcess.referral = refResp.data.createReferral;
        // setActiveProcess(newActiveProcess);
        setAsset(() => ({ ...asset, activeProcess: newActiveProcess }));
        if ('errors' in refResp) {
          addMessage({ message: refResp.errors?.[0]?.message , type: "error" });
        } else {
          addMessage({ message: "Referral sent." });
          await client.refetchQueries({ include: "active" });
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setErrorMessage(error);
        addMessage({
          message:
            "The server encountered an error while starting the referral process. Please try again.",
          type: "error",
        });
      }
    } else {
      setErrorMessage("Please complete all necessary fields.");
    }
  }
  
  if (loading || response.loading)
    return <CircularProgress />;

  if (userType !== "PORTFOLIO_MANAGER") {
    return (
      <Container sx={{ mt: 3, border: `1px solid ${theme.themeColor.sectionStroke}`, borderRadius: '5px' }}>
        <Typography mt="30%" align="center" component="h1" variant="h4">
          Please contact your Portfolio Manager to refer this asset.
        </Typography>
      </Container>
    )
  }

  return (
    <Container sx={{
      pb: 5,
      border: `1px solid ${theme.themeColor.sectionStroke}`,
      borderRadius: '5px', background: theme.themeColor.backgroundBody,
      maxHeight: "85vh",
      overflowY: "auto"
    }}
    >
      <Typography className="submit-referral" mt={5} component="h1" variant="h3" align="center">Start Process</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Typography>Select Properties to Refer: </Typography>
        <FormControl fullWidth>
          <Select
            id='additional-props-dropdown'
            value={selectedProps && selectedProps.map((prop) => prop.assetAddress)}
            name={selectedProps && selectedProps.map((prop) => prop.id)}
            multiple
            inputProps={{ 'data-testid': 'id-select-properties' }}
            data-testid="id-select-properties-dropdown"
            onChange={(_event, properties) => {
              const { props } = properties;
              if (props.id === asset.id) return;
              if (selectedProps.find((prop) => prop.id === props.id)) {
                setSelectedProps(selectedProps.filter((prop) => prop.id !== props.id));
                return;
              } 
              setSelectedProps(prev => [...prev, props]);
            }}
          >
            { allProps &&
                allProps.map((prop) => {
                  return (
                    <MenuItem
                      key={prop.assetAddress}
                      id={prop.id}
                      value={prop.assetAddress}
                      data-testid={`id-${prop.assetAddress.split(' ')[0]}`}
                      {...prop}
                    >
                      {prop.assetAddress}
                    </MenuItem>
                  )
                })
            }
          </Select>
        </FormControl>

        <Typography>Select Law Firm: </Typography>
        <FormControl fullWidth>
          <InputLabel>Law Firm</InputLabel>
          <Select
            id='lawfirm-dropdown'
            value={assignedFirm && assignedFirm.value}
            name={assignedFirm && assignedFirm.id}
            label="Law Firm"
            inputProps={{ 'data-testid': 'id-lawfirm-dropdown-props'}}
            data-testid="id-lawfirm-dropdown"
            onChange={(_event, lawfirm) => {
              const {props} = lawfirm;
              setAssignedFirm({
                id: props.id,
                value: props.value,
              })
            }}
          >
            {lawFirms &&
              lawFirms.map((item) => {
                return (
                  <MenuItem
                    key={item.id}
                    id={item.id}
                    value={item.name}
                    data-testid={`id-${item.name.split(' ')[0]}`}
                  >
                    {item.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <Typography>Referral Start Date: </Typography>
          <DatePickerSelect
            placeholder="Start Date"
            selected={referralStart}
            setSelected={(value) => {
              setReferralStart(value);
            }}
            error={errorForm.referralStart}
            data-testid="id-referral-start"
            fullWidth
          />
        </FormControl>

        <FormControl fullWidth>
          <Typography>Process Type:</Typography>
          <Select
            id='process-type-dropdown'
            value={processType && processType.value}
            name={processType && processType.name}
            inputProps={{ 'data-testid': 'id-process-dropdown-props' }}
            data-testid="id-process-dropdown"
            onChange={(_event, newVal) => {
              const { props } = newVal;
              setProcessType({
                name: props.name,
                value: props.value
              })
            }}
          >
            {allProcessTypes &&
              allProcessTypes.map((item) => {
                return (
                  <MenuItem
                    key={item.value}
                    label={item.name}
                    id={item.value}
                    value={item.value}
                    data-testid={`id-${item.value.split(' ')[0]}`}
                  >
                    {item.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <Typography>Start at Milestone:</Typography>
          <Select
            id='milestone-dropdown'
            value={selectedStep && selectedStep.stepID}
            name={selectedStep && selectedStep.label}
            disabled={!processType || !processType.value}
            inputProps={{ 'data-testid': 'id-milestone-dropdown-props' }}
            data-testid="id-milestone-dropdown"
            onChange={(_event, newValue) => {
              const { props } = newValue;
              setSelectedStep({
                stepID: props.id,
                label: props.label
              })
            }}
          >
            {allSteps &&
              allSteps.map((item) => {
                return (
                  <MenuItem
                    key={item.stepID}
                    label={item.label}
                    id={item.stepID}
                    value={item.stepID}
                  >
                    {item.stepID} - {item.label}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>

        <FormControl fullwidth>
          <Typography>Loan Type: </Typography>
          <Select
            placeholder="Loan Type"
            id='loan-type'
            value={loanType && loanType.value}
            name='loanType'
            inputProps={{ 'data-testid': 'id-loantype-dropdown' }}
            onChange={(_event, newVal) => {
              const { props } = newVal;
              setLoanType({
                name: props.name,
                value: props.value
              })
            }}
          >
            {loanTypes.map((item) => {
              return (
                <MenuItem
                  key={item}
                  label={item}
                  id={item}
                  value={item}
                  data-testid={`id-${item.split(' ')[0]}`}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl fullwidth>
          <Typography>Current AITNO: </Typography>
          <TextInput 
            placeholder="Lender Entity Name" 
            name="currentAITNO"
            value={currentAITNO}
            onChange={(event) => setCurrentAITNO(event.target.value)}
            theme={theme}
          />
        </FormControl>

        <FormControl fullwidth>
          <Typography>Next Due Date: </Typography>
          <DatePickerSelect
            placeholder="Next Due Date"
            selected={nextDueDate}
            setSelected={setNextDueDate}
            fullWidth
            data-testid="id-next-due-date"
          />
        </FormControl>

        <FormControl fullwidth>
          <Typography>Total Payoff: </Typography>
          <TextInput 
            placeholder="Total Payoff" 
            name="totalPayoff"
            type="number"
            value={totalPayoff}
            onChange={(event) => setTotalPayoff(event.target.value)}
            theme={theme}
          />
        </FormControl>
          
        <FormControl fullwidth>
          <Typography>Total Reinstatement: </Typography>
          <TextInput 
            placeholder="Total Reinstatement" 
            name="totalReinstatement"
            type="number"
            value={totalReinstatement}
            onChange={(event) => setTotalReinstatement(event.target.value)}
            theme={theme}
          />
        </FormControl>

        <FormControl fullwidth>
          <Typography>Initial Comment: </Typography>
          <StyledTextareaAutosize
            name="comment"
            minRows={5}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
          />
        </FormControl>

        {errorMessage && (
          <ErrorMessage className='text-center' error={errorMessage} />
        )}

        <Button
          type='submit'
          onClick={handleSubmit}
          disabled={userType !== "PORTFOLIO_MANAGER" || isLoading}
          sx={{ mb: 2, mt: 2}}
        >
          Refer Asset
        </Button>
            
      </Box>

      <HasArchivedProcess
        open={showDeletedModal}
        onClose={() => setShowDeletedModal(false)}
        assetID={asset.id}
        portfolioID={portfolioID}
        loanNumber={loanNumber}
        state={state}
        userID={userID}
        handleRerefer={handleSubmit}
        asset={asset}
        // setActiveProcess={setActiveProcess}
        setAsset={setAsset}
      />
        
    </Container>
  )
}